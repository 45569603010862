import { MLink } from "src/ui/Link/MLink"
import { MText } from "src/ui/MText"

import mastekLogo from "./mastek_logo.png"

export function CustomLogo({ logo }: { logo: "mastek" }) {
  const src = logo === "mastek" ? mastekLogo : undefined

  return (
    <div>
      <img width="200" src={src} alt="logo" />
      <MText variant="nano" color="secondary">
        <span>Powered by</span>{" "}
        <MLink href="https://www.minut.com" underline="never">
          Minut
        </MLink>
      </MText>
    </div>
  )
}
