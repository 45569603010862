import { useState } from "react"
import { useMatch } from "react-router-dom-v5-compat"
import styled from "styled-components"

import IconButton from "@material-ui/core/IconButton"
import ToolbarMui from "@material-ui/core/Toolbar"

import { PARADISE_PATH } from "src/router/ParadiseRoutes"
import { palette } from "src/ui/colors"
import MenuIcon from "src/ui/icons/hamburger.svg"
import Logo from "src/ui/icons/logo.svg"
import { spacing } from "src/ui/spacing"

import { NavDrawer } from "./NavDrawer"
import { ParadiseProfileButton } from "./ParadiseProfileButton"
import { ProfileButton } from "./ProfileButton"

export function AppBarMobile() {
  const [mobileOpen, setMobileOpen] = useState(false)
  const isInParadise = useMatch(PARADISE_PATH + "/*")

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <AppBar>
      <ToolBar $background={isInParadise ? palette.hejmo : "white"}>
        <LogoBox>
          <Logo width={70} fill={isInParadise ? "#fff" : ""} />
        </LogoBox>
        {isInParadise ? (
          <ParadiseProfileButton showLabel={false} />
        ) : (
          <ProfileButton showLabel={false} />
        )}

        <IconButton
          color={isInParadise ? "secondary" : "inherit"}
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon width={28} />
        </IconButton>
      </ToolBar>
      <NavDrawer open={mobileOpen} onClose={() => setMobileOpen(false)} />
    </AppBar>
  )
}

const AppBar = styled.div`
  box-shadow: 0px 3px 4px -4px rgba(0, 0, 0, 0.3);
`

const ToolBar = styled(ToolbarMui)<{ $background: string }>`
  padding: ${spacing.XS} 8px ${spacing.XS} ${spacing.L};
  background-color: ${(props) => props.$background};
  color: ${palette.hav};
`

const LogoBox = styled.div`
  flex: 1;
`
