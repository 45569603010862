import { useMatch } from "react-router-dom-v5-compat"
import styled from "styled-components"

import DrawerMui from "@material-ui/core/Drawer"

import { PARADISE_PATH } from "src/router/ParadiseRoutes"
import { backgroundGrayV2 } from "src/ui/colors"

import { ParadiseSideNav } from "./SideNav/ParadiseSideNav"
import { SideNav } from "./SideNav/SideNav"

export function NavDrawer({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  const isInParadise = useMatch(PARADISE_PATH + "/*")

  const container =
    window !== undefined ? () => window.document.body : undefined

  return (
    <Drawer
      container={container}
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {isInParadise ? (
        <ParadiseSideNav navLinkClick={onClose} />
      ) : (
        <SideNav navLinkClick={onClose} />
      )}
    </Drawer>
  )
}

const Drawer = styled(DrawerMui)`
  & .MuiDrawer-paper {
    background-color: ${backgroundGrayV2};
    /* add overrides here */
  }
`
