import styled from "styled-components"

import { ErrorBanner } from "src/components/AppLayout/Banners/ErrorBanner"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { hasAccessToParadise } from "src/data/user/logic/accessLogic"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { DUNNING_STAGE } from "src/data/user/user"
import { useAppLocation } from "src/hooks/useAppLocation"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DunningBanner() {
  const { navigate } = useRouter()
  const location = useAppLocation()
  const dunningData = useDunning()
  const user = useGetUser()

  const hideOnPages = [
    Routes.AccountBilling.location().pathname,
    Routes.AccountGeneral.location().pathname,
  ]

  if (
    hideOnPages.some((page) => location.pathname.includes(page)) ||
    dunningData.stage < DUNNING_STAGE.DUNNING_DAY_0 ||
    hasAccessToParadise(user.roles)
  )
    return null

  function handleClick() {
    navigate(Routes.AccountBilling.location())
  }
  const Action = dunningData.isOwner && (
    <ActionWrapper>
      <MButton onClick={handleClick} size="small">
        {dunningData.action}
      </MButton>
      {dunningData.actionSecondary && (
        <MButton
          onClick={() => navigate(Routes.Homes.location())}
          variant="subtle"
          size="small"
        >
          {dunningData.actionSecondary}
        </MButton>
      )}
    </ActionWrapper>
  )

  if (dunningData.isCancelled) {
    return (
      <StyledBanner>
        <StyledAlert type="info" fullWidth size="large">
          <MText variant="heading2">{dunningData.banner.header}</MText>
          <MText variant="body" marginBottom={spacing.M}>
            {dunningData.banner.text}
          </MText>
          {Action}
        </StyledAlert>
      </StyledBanner>
    )
  }

  return (
    <StyledBanner>
      <ErrorBanner
        title={dunningData.banner.header}
        description={dunningData.banner.text}
        action={Action}
      />
    </StyledBanner>
  )
}

const StyledBanner = styled.div`
  padding: ${spacing.M};
  @media (min-width: 800px) {
    padding: ${spacing.XL3};
  }
`

const StyledAlert = styled(MBanner)`
  display: grid;
  grid-gap: ${spacing.M};
`

const ActionWrapper = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
  flex-wrap: wrap;
`
