import styled, { css } from "styled-components"

import { ListItem, ListItemIcon, Menu as MuiMenu } from "@material-ui/core"

import { breakpoint } from "src/constants/breakpoints"
import { palette } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export const mainContentPadding = css`
  padding: ${spacing.M};
  @media (${breakpoint.mediumUp}) {
    padding: ${spacing.L} ${spacing.L} ${spacing.L} ${spacing.XL3};
  }
`

export const StyledListItemIcon = styled(ListItemIcon)`
  color: ${palette.hejmo};
  justify-content: center;
`

export const ProfileInfo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.S};
  align-items: center;
  width: 100%;
  text-align: left;
`

export const ProfileInfoCenter = styled.div`
  line-height: 1.4;
  overflow: hidden;
`

export const ProfileInfoCenterTitle = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: ${spacing.XS};
  margin-right: ${spacing.XS};
`

export const StyledListItem = styled(ListItem)`
  padding-left: 5px;
`
// This is a hack to workaround that MUI ListItems force a button prop when
// using it as a styled component
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export const ProfileListItem = styled(StyledListItem as any)`
  margin: 5px 0;
`

export const ellipsis = css`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Fullname = styled.div`
  ${ellipsis};
`

export const Menu = styled(MuiMenu)`
  & .MuiList-padding {
    padding: 0;
  }
`
