import styled from "styled-components"

import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { ExpandableAttentionCard } from "src/ui/ExpandableSection/ExpandableAttentionCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

export function HomeTokenExpirationExpandable({
  numberOfExpiringTokens,
  date,
}: {
  numberOfExpiringTokens: number
  date: string
}) {
  const user = useGetUser()
  const { t, langKeys } = useTranslate()

  const { navigate } = useRouter()

  return (
    <ExpandableAttentionCard
      type="warning"
      title={t(langKeys.home_token_expiration_expandable_title, {
        count: numberOfExpiringTokens,
      })}
      initialOpen
    >
      <Content>
        <MText>
          {t(langKeys.home_token_expiration_expandable_body, {
            count: numberOfExpiringTokens,
            date: formatDate({
              date,
              clockType: user.clock_type,
              excludeTime: true,
            }),
          })}
        </MText>
        <MButton
          onClick={() => {
            navigate(Routes.CreateHome.location())
          }}
        >
          {t(langKeys.create_home)}
        </MButton>
      </Content>
    </ExpandableAttentionCard>
  )
}

const Content = styled.div`
  display: grid;
  justify-items: start;
  gap: ${spacing.M};
`
